import React from "react"
import { graphql } from "gatsby"
import { rhythm } from "src/utils/typography"
import TemplateLayout from "src/components/TemplateLayout"
import SEO from "src/components/seo"

const ProjectPageTemplate = ({ data, location }) => {   
    const project = data.markdownRemark
    const mainImage = data.markdownRemark.frontmatter.mainImage.childImageSharp.fluid

return (
  <TemplateLayout destination="/portfolio" mainImage={mainImage}>
    <SEO title={project.frontmatter.title} description={project.frontmatter.description || project.excerpt}/>
    <article>
      <header>
        <h1
          style={{
            marginTop: rhythm(1),
            marginBottom: 0,
          }}
        >
          {project.frontmatter.title}
        </h1>
      </header>
      <section style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}} dangerouslySetInnerHTML={{ __html: project.html }} />
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
    </article>
  </TemplateLayout>
  )
}

export default ProjectPageTemplate

export const pageQuery = graphql`
  query ProjectPageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        mainImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
      }
    }
  }
`
